import * as React from 'react';
import Layout from '../components/Layout/Layout';
import * as styles from './index.module.scss';
// import { StaticImage } from 'gatsby-plugin-image';
import { IconContext } from 'react-icons';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { Heading } from '../components/Heading/Heading';
import { Text } from '../components/Text/Text';
import Seo from '../components/Seo/Seo';
import clsx from 'clsx';
import { HiOutlineChevronDoubleRight as Chevron } from 'react-icons/hi';
import { Link } from 'gatsby';
import {
  BsArrowRightCircle as ArrowButton,
  BsHandThumbsUp as ThumbUp,
} from 'react-icons/bs';
import ReCAPTCHA from 'react-google-recaptcha';

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const IndexPage = () => {
  const [state, setState] = React.useState({});
  const [formIsSent, setFormIsSent] = React.useState(false);
  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => setFormIsSent(true))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <Seo
        title={`Déboucheur de canalisation professionnel | Lège-Cap-Ferret (33236 /33950) | Fabrice Anton, Bassin Pro Service`}
        description={`Contacter un hydrocureur professionnel pour le débouchage et l'hydrocurage de vos canalisations bouchées, de votre réseau d'assainissement encombré, de votre réseau d'évacuation d'eaux usées bouché. Bassin Pro Services opère dans les villes de: Audenge, Lanton, Cassy, Taussat, Andernos-les-Bains, Arès, Lège, Lège-Cap-Ferret, Claouey, Piquey, L'Herbe, Cap-Ferret, Lacanau, Blagon, Le Temple. Disponible 24h/24 & 7j/7`}
        lang={'fr'}
      />

      <div className='text-center'>
        <div className={styles.hero}>
          <div
            className={clsx({
              [`container`]: true,
            })}
          >
            {/* ============== */}
            <section
              className={clsx({
                [styles.banner]: true,
                ['row']: true,
              })}
            >
              <div className='col-12'>
                <Heading
                  tagName={'h2'}
                  styleType={'h2'}
                  textAlignment={'center'}
                  textColour={'white'}
                >
                  CONTACT
                </Heading>
              </div>
            </section>
            <div className='row'>
              <div className='col-12'>
                <div className={styles.breadcrumb}>
                  <Link to='/' className={styles.linkBreadcrumb}>
                    BASSIN PRO SERVICES
                  </Link>
                  <span className={styles.chevron}>
                    <IconContext.Provider
                      value={{ size: '1em', color: '#15acdb' }}
                    >
                      <Chevron title='Icon intervention débouchage des conduits souterrains' />
                    </IconContext.Provider>
                  </span>
                  <span className={styles.textBreadcrumb}>CONTACT</span>
                </div>
              </div>
            </div>
          </div>
          {/* <StaticImage
            src='../images/hero-image-lavabo-2.jpg'
            alt='Un systeme de canalisation entretenu par un professionnel Fabrice Anton'
          /> */}
        </div>
      </div>
      <div className='container'>
        {/* ============== */}
        <section className='row' id='services'>
          <div className='col-12 col-xll-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Bassin Pro Services, à votre écoute'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Contactez-nous pour prendre un rendez-vous`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Les bureaux de Bassin Pro services sont situés à Lège-Cap-Ferret,
              nous nous rendrons chez vous facilement si vous êtes dans la
              région du Nord Bassin.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous intervenons 7j/7 et 24h/24 sans majoration. Le tarif vous
              sera indiqué avant notre déplacement et une fois que nous aurons
              connaissance de votre problème.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Pour une urgence prioritaire, nous vous conseillons de nous
              appeler directement.
            </Text>
          </div>
        </section>
        <section className='row' id='services'>
          <div className='col-12 col-lg-8'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Une question ? Un rendez-vous ?'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Nous vous écoutons`}
                textColour={'primary'}
              />
            </header>
            {formIsSent ? (
              <div className={styles.formSent}>
                <div className={styles.iconFormSent}>
                  <IconContext.Provider
                    value={{ size: '3em', color: '#15acdb' }}
                  >
                    <ThumbUp title='Icon intervention débouchage des conduits souterrains' />
                  </IconContext.Provider>
                </div>
                <Heading
                  tagName={'h4'}
                  styleType={'h3'}
                  textAlignment={'center'}
                  text={'Votre formulaire a correctement été envoyé.'}
                  textColour={'tertiary'}
                />
                <Text
                  tagName={'p'}
                  styleType={'bodyRegular'}
                  fontWeight={'bold'}
                  textAlignment={'center'}
                >
                  Nous vous en remercions et revenons vers vous dans les plus
                  brefs délais.
                </Text>
              </div>
            ) : (
              <>
                <Text tagName={'p'} styleType={'bodyRegular'}>
                  N’hésitez pas à envoyer un message via le formulaire
                  ci-dessous. Nous nous engageons à y répondre dans les 24
                  heures.
                </Text>
                <form
                  name='contact'
                  method='post'
                  action='/thanks/'
                  data-netlify='true'
                  data-netlify-recaptcha='true'
                  data-netlify-honeypot='bot-field'
                  onSubmit={handleSubmit}
                  className='mt-4'
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type='hidden' name='form-name' value='contact' />
                  <p hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name='bot-field' onChange={handleChange} />
                    </label>
                  </p>

                  {/* ---------------- */}
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='mb-3'>
                        <label htmlFor='userSurname' className='form-label'>
                          Nom
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='userSurname'
                          name='userSurname'
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='mb-3'>
                        <label htmlFor='userName' className='form-label'>
                          Prénom
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='userName'
                          name='userName'
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='mb-3'>
                        <label htmlFor='userPhone' className='form-label'>
                          Téléphone
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='userPhone'
                          aria-describedby='phoneHelp'
                          name='userPhone'
                          required
                          onChange={handleChange}
                        />
                        <div id='phoneHelp' className='form-text'>
                          Nous ne partagerons jamais votre téléphone avec qui
                          que ce soit.
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='mb-3'>
                        <label htmlFor='userEmail' className='form-label'>
                          Email
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          id='userEmail'
                          aria-describedby='emailHelp'
                          name='userEmail'
                          required
                          onChange={handleChange}
                        />
                        <div id='emailHelp' className='form-text'>
                          Nous ne partagerons jamais votre adresse email avec
                          qui que ce soit.
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='mb-3'>
                        <label htmlFor='userCity' className='form-label'>
                          Ville
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='userCity'
                          name='userCity'
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='mb-3'>
                        <label
                          htmlFor='typeIntervention'
                          className='form-label'
                        >
                          Type d'intervention
                        </label>
                        <select
                          className='form-select'
                          name='typeIntervention'
                          aria-label="Selectionnez le type d'intervention"
                          onChange={handleChange}
                          required
                        >
                          <option value='null'>
                            Sélectionnez le type d'intervention
                          </option>
                          <option value='debouchage'>Débouchage</option>
                          <option value='inspectionCamera'>
                            Inspection avec caméra
                          </option>
                          <option value='hydrocurage'>Hydrocurage</option>
                          <option value='entretien'>Entretien</option>
                          <option value='autre'>Autre</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='userMessage' className='form-label'>
                      Votre message
                    </label>
                    <textarea
                      className='form-control'
                      id='userMessage'
                      name='userMessage'
                      rows='3'
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className='mb-3'>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
                  </div>

                  <div className='mb-3'>
                    <button type='submit' className={styles.linkButton}>
                      <span className={styles.label}>
                        Envoyer le formulaire
                      </span>
                      <IconContext.Provider
                        value={{ size: '1.5em', color: '#FFF' }}
                      >
                        <ArrowButton title='Icon intervention débouchage des conduits souterrains' />
                      </IconContext.Provider>
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </section>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Horaires d'ouverture de Bassin Pro Services`}
                textColour={'primary'}
              />
            </header>
            <ul className={styles.bulletList}>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon intervention débouchage des conduits souterrains' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  les week-ends
                </Text>
              </li>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon entretien des canalisations' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  les jours fériés
                </Text>
              </li>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon entretien des canalisations' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  Service urgence 24h/24 & 7j/7
                </Text>
              </li>
            </ul>
          </div>
        </section>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Tous nos contacts`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'} fontWeight={'bold'}>
              Téléphone / Service urgence:
              <br />
              06 24 09 33 40
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'} fontWeight={'bold'}>
              Email:
              <br />
              <a href='mailto:contact@bassin-pro-services.com'>
                contact@bassin-pro-services.com
              </a>
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'} fontWeight={'bold'}>
              Twitter:
              <br />
              <a href='https://twitter.com/b_pro_services' target={'_blank'}>
                https://twitter.com/b_pro_services
              </a>
            </Text>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
